





































import { Component, Vue, Prop } from 'vue-property-decorator'
import DeviceItemContent from '@/components/device/item-content.vue'
import DeviceItemLogs from '@/components/device/item-logs.vue'
import { Device } from '@/models/devices'

@Component<DeviceItem>({
  components: { DeviceItemContent, DeviceItemLogs }
})
export default class DeviceItem extends Vue {
  @Prop({ type: String }) id!: string | null
  @Prop({ type: Object }) device!: Device | null
  showLog = !!this.id

  get xs () { return this.$vuetify.breakpoint.xs }
}
